
/**
 * @roxi/routify 2.18.5
 * File generated Fri May 20 2022 15:31:24 GMT+0100 (British Summer Time)
 */

export const __version = "2.18.5"
export const __timestamp = "2022-05-20T14:31:24.394Z"

//buildRoutes
import { buildClientTree } from "@roxi/routify/runtime/buildRoutes"

//imports


//options
export const options = {}

//tree
export const _tree = {
  "root": true,
  "children": [
    {
      "isDir": true,
      "ext": "",
      "children": [
        {
          "isDir": true,
          "ext": "",
          "children": [
            {
              "isPage": true,
              "path": "/admin/edit/:id",
              "id": "_admin_edit__id",
              "component": () => import('../src/pages/admin/edit/[id].svelte').then(m => m.default)
            }
          ],
          "path": "/admin/edit"
        },
        {
          "isIndex": true,
          "isPage": true,
          "path": "/admin/index",
          "id": "_admin_index",
          "component": () => import('../src/pages/admin/index.svelte').then(m => m.default)
        }
      ],
      "path": "/admin"
    },
    {
      "isIndex": true,
      "isPage": true,
      "path": "/index",
      "id": "_index",
      "component": () => import('../src/pages/index.svelte').then(m => m.default)
    },
    {
      "isDir": true,
      "ext": "",
      "children": [
        {
          "isDir": true,
          "children": [
            {
              "isPage": true,
              "path": "/pictures/:reference/:pictureid",
              "id": "_pictures__reference__pictureid",
              "component": () => import('../src/pages/pictures/[reference]/[pictureid].svelte').then(m => m.default)
            },
            {
              "isIndex": true,
              "isPage": true,
              "path": "/pictures/:reference/index",
              "id": "_pictures__reference_index",
              "component": () => import('../src/pages/pictures/[reference]/index.svelte').then(m => m.default)
            }
          ],
          "isLayout": true,
          "path": "/pictures/:reference",
          "id": "_pictures__reference__layout",
          "component": () => import('../src/pages/pictures/[reference]/_layout.svelte').then(m => m.default)
        }
      ],
      "path": "/pictures"
    }
  ],
  "isLayout": true,
  "path": "/",
  "id": "__layout",
  "component": () => import('../src/pages/_layout.svelte').then(m => m.default)
}


export const {tree, routes} = buildClientTree(_tree)

