<script lang="ts">
import { Router } from "@roxi/routify";
import { routes } from "../.routify/routes";
</script>

<main>
  <Router {routes} />
</main>

<style>
  main {
    min-height: 100vh;
    width: 100%
  }
</style>